import React from 'react';
import sanitizeHtml from 'sanitize-html';

import GreenStripe from '../../components/GreenStripe';
import Headline from '../../components/headline/Headline';
import { PhotoGallery } from '../../components/PhotoGallery';
import { Section } from '../../components/Section';
import { SEO } from '../../components/Seo';
import { Submenu } from '../../components/Submenu';
import { AllMarkdownRemarkQueryResult } from '../../interfaces/AllMarkdownRemark';
import { MarkdownFileNode } from '../../interfaces/MarkdownFileNode';
import { PageProps } from '../../interfaces/PageProps';
import { allMarkdownRemark } from '../../queries/AllMarkdownRemark';
import { Container, Description, Image } from '../../styles/CommonStyledComponents';
import { decodeUri } from '../../utils/DecodeUri';
import { findMarkdownFile } from '../../utils/FindMarkdownFile';
import { findMarkdownFiles } from '../../utils/FindMarkdownFiles';
import { getKindergartenByPathname } from '../../utils/GetKindergartenByPathname';
import withLocation from '../../utils/WithLocation';

const ActivitiesAndAttractions = (props: PageProps) => {
  const data: AllMarkdownRemarkQueryResult<MarkdownFileNode> | undefined =
    allMarkdownRemark<AllMarkdownRemarkQueryResult<MarkdownFileNode>>();
  const markdownActivitiesFile = findMarkdownFile(data!.allMarkdownRemark, props.location.pathname, 'activities');
  const markdownAttractionsFile = findMarkdownFile(data!.allMarkdownRemark, props.location.pathname, 'attractions');
  const markdownOnlineFile = findMarkdownFile(data!.allMarkdownRemark, props.location.pathname, 'online');
  const acknowledgmentsFile = findMarkdownFiles<MarkdownFileNode>(
    data!.allMarkdownRemark,
    `diplomas/${getKindergartenByPathname(props.location.pathname)}`,
    'acknowledgments',
  )[0];
  const diplomasFile = findMarkdownFiles<MarkdownFileNode>(
    data!.allMarkdownRemark,
    `diplomas/${getKindergartenByPathname(props.location.pathname)}`,
    'diplomas',
  )[0];
  const statuettesFile = findMarkdownFiles<MarkdownFileNode>(
    data!.allMarkdownRemark,
    `diplomas/${getKindergartenByPathname(props.location.pathname)}`,
    'statuettes',
  )[0];

  return (
    <>
      <SEO title={decodeUri(getKindergartenByPathname(props.location.pathname))} />
      <GreenStripe />
      <Submenu
        location={props.location}
        orderCollection={['Zajecia', 'Atrakcje', markdownOnlineFile?.html ? 'Nutka online' : '']}
      ></Submenu>
      <Section id="Zajecia">
        <Headline arrowColor="arrowGreen" title="Zajęcia"></Headline>
        <Container>
          <Image fluid={markdownActivitiesFile?.frontmatter?.image?.childImageSharp.fluid || []} />
          <Description
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(markdownActivitiesFile?.html || ''),
            }}
          ></Description>
        </Container>
      </Section>
      <Section id="Atrakcje">
        <Headline arrowColor="arrowGreen" title="Atrakcje"></Headline>
        <Container>
          <Image fluid={markdownAttractionsFile?.frontmatter?.image?.childImageSharp.fluid || []} />
          <Description
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(markdownAttractionsFile?.html || ''),
            }}
          ></Description>
        </Container>
      </Section>
      {markdownOnlineFile?.html ? (
        <Section id="Nutka online">
          <Headline arrowColor="arrowGreen" title="Nutka online"></Headline>
          <Container>
            <Description
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(markdownOnlineFile?.html || ''),
              }}
            ></Description>
          </Container>
        </Section>
      ) : null}
      <Section>
        {[acknowledgmentsFile, diplomasFile, statuettesFile].map((file, index) => {
          if (file != undefined) {
            return (
              <PhotoGallery
                photos={file.frontmatter?.images?.map((image) => image.image.childImageSharp) || []}
                title={file.frontmatter?.title || ''}
                withImages={false}
                key={index}
              ></PhotoGallery>
            );
          }
        })}
      </Section>
    </>
  );
};

export default withLocation(ActivitiesAndAttractions);
